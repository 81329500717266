<template>
  <div class="loan_item">
    <div class="inner inner1 flex jc-c ai-c">
      <div :style="{background:'#FFFFFF'}">
        <h1>About Us</h1>
        <p>PesoGo VIP, an inclusive-finance production subject to Dolphin Lending Investor,<br>
          Inc., is committed to contributing to building up a healthy, safe, fast, and smart financial<br>
          ecosystem, further providing the folks with financial solutions to their financial difficulties<br>
          with ease.</p>
      </div>
    </div>

    <div class="inner inner2">
      <!-- <h4>Sobre nosotros</h4> -->
      <div class="content">
        <div class="left_cell">
          <h1>Our mission</h1>
          <p>According to data provided by World Bank, for the majority of
            the grown-up in the world, they aren’t entitled to the most
            fundamental financial service.Aiming for “Common Prosperity”,
            PesoGo VIP is dedicated to making financial service more
            prevalent and more accessible to more people, making it benefit
            people more. In terms of key users, PesoGo VIP aspires to
            provide them with safe and convenient financial services at a
            reasonable cost.
          </p>
          <!-- <p>
            To allow customers currently not being served by
            traditional banks to become active in the microcredit
            ecosystem and enable them to build a credit identity;
            in an easy, trustworthy & fast manner.
          </p> -->
        </div>
        <div class="right_cell">
          <img src="@/assets/images/loanImg/about_inner2.png" alt="">
        </div>
      </div>
    </div>
    <div class="inner inner3">
      <div class="content">
        <div class="right_cell">
          <img src="@/assets/images/loanImg/about_inner3.png" alt="">
        </div>
        <div class="left_cell">
          <h1>What have we done?</h1>
          <p>Based on affordable cost, PesoGo VIP provides appropriate
            and valid financial service to all social classes and groups
            with financial service needs. Among them, SMEs, peasants,
            low-income groups, and others are our key users.
          </p>
          <!-- <p>SEC Registration No. CS201911897</p>
          <p>REGISTERED BUSINESS NAME: Microloan</p>
          <p>Authorization Certificate No. 3037</p> -->
        </div>
      </div>
    </div>
    <div class="inner inner4">
      <!-- <h4>Sobre nosotros</h4> -->
      <div class="content">
        <div class="left_cell">
          <h1>Why are we doing so?</h1>
          <p>The theme of “Inclusive Finance” fits perfectly the development
            needs ongoing in the contemporary economical finance fields
            across the world. In the principle of developing sustainable
            commercial and dedicated to boosting the financial potential
            of our users, PesoGo VIP sticks to combine marketization and
            policy boosting, setting up mechanisms of incentive and restrain
            to ensure sustainable development. Amid this process, to meet
            the financial needs of more users, we hope to embrace “Inclusive
            Finance” ardently, aiming for common prosperity.
          </p>
          <!-- <p>
            To allow customers currently not being served by
            traditional banks to become active in the microcredit
            ecosystem and enable them to build a credit identity;
            in an easy, trustworthy & fast manner.
          </p> -->
        </div>
        <div class="right_cell">
          <img src="@/assets/images/loanImg/about_inner4.png" alt="">
        </div>
      </div>
    </div>
    <div class="inner inner6">
      <div class="inner6_item content">
        <div class="inner6_item_right"><img src="@/assets/images/kefu.png" alt=""></div>
        <div class="inner6_item_left">
          <div>
            <h1 class="inner6_item_left_title">Contact Us</h1>
            <div v-for="(item,index) in inner6ItemLeftList" :key="index" class="inner6_item_left_list ">
              <img v-if="item.img" :src="item.img">
              <span>{{ item.title }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inner6ItemLeftList: [
        { title: 'E-mail:pesogoviphelp@outlook.com', img: require('@/assets/images/email.png') },
        { title: 'Address:170 TORRE VENEZIA SUITES TIMOG AVE. COR. SCT. SANTIAGO ST. LAGING HANDA', img: require('@/assets/images/address.png') },
        { title: 'Service Hours : 9:00 - 17:00', img: require('@/assets/images/time.png') },
        { title: 'SEC Registration No.CS201911897;CERTIFICATE OF AUTHORITY NO.3037' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.loan_item{
  .inner{
    min-width: 1100px;
    margin: 0 auto;
    overflow: hidden;
    padding: 50px calc((100% - 1100px) / 2) ;
    h3{
      font-size: 18px;
      color: #3E1FA5;
    }
    .content{
      display: flex;
    }
    .left_cell{
      float: left;
      padding: 60px 0;
      text-align: left;
      h1{
        font-size: 40px;
        margin-bottom: 30px;
      }
      p{
        font-size: 12px;
        line-height: 30px;
        color: #999999;
      }
      .cell_item{
        width: 267px;
        height: 140px;
        margin-top: 18px;
        border-bottom: 1px solid #6E60F9;
      }
    }
  }
  .inner1{
    background: url('~@/assets/images/loan_bg.png') no-repeat;
    background-size: 100% 100%;
    height: 550px;
    display: flex;
    text-align: center;
    div{
      border-radius: 20px;
      padding: 0 200px;
      opacity: 0.8;
      h1{
        font-size: 40px;
        line-height: 140px;
      }
      p{
        color: #666666;
        font-size: 16px;
        text-align: left;
        width: 100%;
        line-height: 30px;
        padding: 0 0 60px ;
      };
    }
  }
  .inner2{
    .content{
      display: flex;
      .left_cell,.right_cell{
        flex: 1;
        display: flex;
      }
      h1{
        font-size: 30px;
      }
      img{
        margin:auto 0;
        width: 420px;
        height: 300px;
      }
      .left_cell{
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          font-size: 12px;
          line-height: 25px;
          color: black;
          padding: 0 170px 0px 0;
        }
      }
      .right_cell{
        display: flex;
        justify-content: right;
      }
    }
  }

  .inner3{
      background: linear-gradient(86deg, #013799 0%, #0071D6 100%);
    .content{
      display: flex;
      color: white;
      .left_cell,.right_cell{
        flex: 1;
        display: flex;
        // justify-content: center;
      }
      h1{
        font-size: 30px;
      }
      img{
        margin: 25px 0;
        width: 420px;
        height: 300px;
        // display: block;
      }
      .left_cell{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 260px;
        p{
          font-size: 12px;
          color: white;
          line-height: 27px;
        }
      }
    }
  }

  .inner4{
    .content{
      display: flex;
      .left_cell,.right_cell{
        flex: 1;
        display: flex;
      }
      h1{
        font-size: 30px;
      }
      img{
        margin:auto 0;
        width: 420px;
        height: 300px;
      }
      .left_cell{
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          font-size: 12px;
          line-height: 25px;
          color: black;
          padding: 0 170px 0px 0;
        }
      }
      .right_cell{
        display: flex;
        justify-content: right;
      }
    }
  }
  .inner6{
    background: linear-gradient(255deg, #0BA5C9 0%, #1DBAA1 100%);
    padding: 60px  0 0 0;
    .inner6_item{
      align-items: center;
      .inner6_item_left{
        flex: 1.5;
        color: #ffffff;
        display: flex;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        width: 50%;
        h1{
          text-align:left;
          margin-bottom: 56px;
          width: auto;
        }
        .inner6_item_left_list{
          display: flex;
          align-items: center;
          margin-bottom: 25px;
          span{
            font-size: 12px;
          }
          img{
            width: 24px;
            height: 24px;
            margin-right: 18px;
          }
        }

      }
      .inner6_item_right{
        flex: 1;
        z-index: 1;
        // margin-left: -40px;
        img{
          margin-bottom: -2px;
          width: 100%;
        }
      }
    }

  }
}
@media only screen and (max-width: 665px){
  .loan_item{
    .inner{
      min-width: calc(100vw) !important;
    }
    .inner1{
      height: 200px;
      color: white;
      padding: 50px 20px 0;
      flex-direction: column;
      div{
        padding: 0 20px !important;
      }
    }

    .inner2{
      h4{
        padding: 0 20px;
      }
      .content{
        padding: 0 20px !important;
        display: flex;
        flex-direction: column;
        .left_cell{
          padding: 30px 0;
          h1{
            text-align: center;
            margin-bottom: 20px;
          }
          p{
            padding: 0 !important;
            width: 100% !important;
            margin:  15px 0;
          }
        }
        .right_cell{
          img{
            width: 100% !important;
            height: 250px !important;
          }
        }
      }
    }
    .inner3{
    // padding-top: 20px;
    margin: 30px 0;
    h4{
      padding: 0 20px;
    }
    .content{
      padding: 0 20px !important;
      display: flex;
      flex-direction: column;
      .left_cell{
        width: 100%;
        margin-left: 0px;
        padding: 30px 0;
        p{
          padding: 0 !important;
          width: 100% !important;
          // margin:  10px 0;
        }
      }
      .right_cell{
        img{
          width: 100% !important;
          height: 250px !important;
        }
      }
    }
    }
    .inner4{
    h4{
      padding: 0 20px;
    }
    .content{
      padding: 0 20px !important;
      display: flex;
      flex-direction: column;
      .left_cell{
        padding: 30px 0;
        h1{
          text-align: center;
          margin-bottom: 20px;
        }
        p{
          padding: 0 !important;
          width: 100% !important;
          margin:  15px 0;
        }
      }
      .right_cell{
        img{
          width: 100% !important;
          height: 250px !important;
        }
      }
    }
  }
  }
  .inner6{
    // padding: 30px 20px 30px;
    h3{
      text-align: center;
      margin-bottom: 30px;
    }
    .inner6_item{
      width: 100%;
      flex-direction: column;
      .inner6_item_left{
        width: calc(100% - 40px) !important;
        padding: 20px 0;
        .inner6_item_left_title{
          font-size: 20px !important;
          text-align: center;
        }
        .inner6_item_left_list{
          justify-content: left;
          img{
            width: 17px;
            height: 17px;
          }
          span{
            font-size: 12px;
          }
        }
      }
      .inner6_item_right{
        width: calc(100% - 80px) !important;
        margin-left: 0 !important;
        margin: -20px 0 0;
        z-index: 2;
      }
    }
  }
}
</style>
